import * as pageNames from "@/constants/page_names";
import store from "@/store";
import { TOKEN_REFRESH_ACTION } from "@/constants/store_actions";

const nonRestrictedPages = [
  pageNames.SIGN_IN,
  pageNames.SIGN_UP,
  pageNames.CASHIER,
  pageNames.SCANNER,
];

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
  } else {
    next({ name: pageNames.HOME_PAGE });
  }
};

const fixLocation = () => {
  window.location.replace(
    window.location.origin + "/" + window.location.hash + window.location.search
  );
};

const handleTokenRefresh = async (to, from, next) => {
  if (from.name !== pageNames.SIGN_IN && from.name !== pageNames.SIGN_UP) {
    try {
      await store.dispatch(TOKEN_REFRESH_ACTION);
      next();
    } catch (error) {
      if (to.name === pageNames.HOME_PAGE) {
        next();
      } else
        next({ name: pageNames.SIGN_IN, query: { targetPath: to.fullPath } });
    }
  } else {
    next();
  }
};

export const globalBeforeEachGuard = async (to, from, next) => {
  const isLocationInvalid = window.location.search !== "";
  const isUserAuthenticated = store.getters.isAuthenticated;
  const isPageRestricted = !nonRestrictedPages.includes(to.name);
  if (isLocationInvalid) {
    fixLocation();
    next(false);
  } else if (isUserAuthenticated && isPageRestricted) {
    await handleTokenRefresh(to, from, next);
  } else if (isPageRestricted) {
    next({ name: pageNames.SIGN_IN, query: { targetPath: to.fullPath } });
  } else {
    next();
  }
};
