export const TOKEN_REFRESH = "merchant/refresh/";
export const OTP_SEND_CODE_ROUTE = "merchant/login/send-code/";
export const OTP_LOGIN_ROUTE = "merchant/login/otp/";
export const NON_CO_CONTROLLED_LOGIN_ROUTE =
  "merchant/login/non-co-controlled/";
export const WHITELIST_LOOKUP_ROUTE = (email) => `whitelist/${email}/`;
export const MERCHANT_LIST_CREATE = "merchant/";
export const MERCHANT_ROUTE = (userId) => `merchant/${userId}/`;
export const TOKEN_CREATE_ROUTE = "token/";
export const TOKEN_REDEEM_ROUTE = (tokenId) => `token/${tokenId}/redemption/`;
export const TOKEN_REISSUE_ROUTE = (tokenId) => `token/${tokenId}/issuance/`;
export const TOKENS_ROUTE = (userId) => `merchant/${userId}/token/`;
export const MERCHANT_SPACES_ROUTE = (userId) => `merchant/${userId}/space/`;
export const SPACE_CREATE_ROUTE = "space/";
export const SPACE_ROUTE = (spaceId) => `space/${spaceId}/`;
export const PAYMENT_OPTIONS_ROUTE = (spaceId) =>
  `space/${spaceId}/payment-option/`;
export const PAYMENT_OPTION_DETAILS_ROUTE = (paymentOptionId) =>
  `payment-option/${paymentOptionId}/`;
export const MERCHANT_OFFERS_ROUTE = (userId) => `merchant/${userId}/offer/`;
export const OFFER_ROUTE = (offerId) => `offer/${offerId}/`;
export const OFFER_CREATE_ROUTE = (spaceId) => `space/${spaceId}/offer/`;
export const WALLETS_ROUTE = (spaceId) => `space/${spaceId}/wallet/`;
export const PROMO_RULE_LIST_CREATE = (userId) =>
  `merchant/${userId}/promo-rule/`;
export const PROMO_RULE_DETAILS_ROUTE = (promoRuleId) =>
  `promo-rule/${promoRuleId}/`;
export const GLOBAL_PROMO_RULE_LIST_CREATE = (userId) =>
  `merchant/${userId}/global-promo-rule/`;
export const GLOBAL_PROMO_RULE_DETAILS_ROUTE = (promoRuleId) =>
  `global-promo-rule/${promoRuleId}/`;
export const CASHIER_LOGIN_ROUTE = `cashier/login/`;
export const CASHIER_OFFER_ROUTE = (cashierId, spaceId) =>
  `cashier/${cashierId}/space/${spaceId}/offer/`;
export const CASHIER_ROUTE = (cashierId) => `cashier/${cashierId}/`;
export const CASHIER_LIST_CREATE = (spaceId) => `space/${spaceId}/cashier/`;
export const COLLECTOR_LOGIN_ROUTE = `collector/login/`;
export const COLLECTOR_ROUTE = (cashierId) => `collector/${cashierId}/`;
export const COLLECTOR_LIST_CREATE = (spaceId) => `space/${spaceId}/collector/`;
export const SUPPLIER_TOKENS_ROUTE = (spaceId) => `space/${spaceId}/token/`;
export const WALLET_ADDRESS_RETRIEVE_ROUTE = "wallet/address/";
export const WALLET_ASSETS_SEND_ROUTE = "wallet/send/";
export const WALLET_BALANCE_RETRIEVE_ROUTE = "wallet/balance/";
export const WALLET_HISTORY_RETRIEVE_ROUTE = "wallet/history/";
export const WALLET_SYNC_ROUTE = "wallet/sync/";
