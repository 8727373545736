export const HOME_PAGE = "home";
export const SIGN_IN = "sign-in";
export const SIGN_UP = "sign-up";
export const TOKEN_CREATE = "token-create";
export const TOKENS = "tokens";
export const SPACES = "spaces";
export const SPACE_CREATE = "space-create";
export const SPACE_DETAILS = "space-details";
export const OFFERS = "offers";
export const OFFER_CREATE = "offer-create";
export const OFFER_DETAILS = "offer-details";
export const MERCHANT = "merchant";
export const WALLET = "wallet";
export const WALLETS = "wallets";
export const WALLEE_INSTALLATION = "wallee-installation";
export const CASHIER = "cashier";
export const CASHIERS = "cashiers";
export const PROMO_RULES = "promo-rules";
export const PROMO_RULE_CREATE = "promo-rule-create";
export const PROMO_RULE_DETAILS = "promo-rule-details";
export const GLOBAL_PROMO_RULES = "global-promo-rules";
export const GLOBAL_PROMO_RULE_CREATE = "global-promo-rule-create";
export const GLOBAL_PROMO_RULE_DETAILS = "global-promo-rule-details";
export const SCANNER = "scanner";
export const COLLECTORS = "collectors";
export const DASHBOARD = "dashboard";
