<template>
  <div id="video-container">
    <canvas ref="canvas"></canvas>
    <video hidden ref="video" />
    <b-form-select
      v-if="stream"
      id="video-source-select"
      v-model="videoSource"
      :options="videoSourceOptions"
      v-on:change="getStream"
    ></b-form-select>
    <b-button
      variant="danger"
      id="scanner-cancel-button"
      v-on:click="$emit('stop')"
      >Cancel</b-button
    >
  </div>
</template>

<script>
import jsQR from "jsqr";

export default {
  name: "TheQRScanner",
  data() {
    return {
      videoSource: null,
      stream: null,
      videoSourceOptions: [],
      isScanning: false,
      decodedData: null,
      scannerFPS: 10,
      videoFPS: 20,
      storageCameraSettingIndex: "scanner-camera",
    };
  },
  watch: {
    decodedData: {
      handler: function (newValue, oldValue) {
        if (oldValue === null) {
          this.$data.isScanning = false;
          this.$emit("decoded", newValue.data);
        }
      },
    },
  },
  methods: {
    setCanvasSize() {
      const aspectRatio =
        this.$refs.video.videoWidth / this.$refs.video.videoHeight;
      if (this.$refs.video.videoWidth < this.$refs.video.videoHeight) {
        this.$refs.canvas.height = this.$refs.canvas.offsetHeight;
        this.$refs.canvas.width = this.$refs.canvas.height * aspectRatio;
      } else {
        this.$refs.canvas.width = this.$refs.canvas.offsetWidth;
        this.$refs.canvas.height = this.$refs.canvas.width / aspectRatio;
      }
    },
    async getDevices(deviceInfos) {
      deviceInfos = await navigator.mediaDevices.enumerateDevices();
      for (const deviceInfo of deviceInfos) {
        if (deviceInfo.kind === "videoinput") {
          const option = {
            text:
              deviceInfo.label ||
              `Camera ${this.videoSourceOptions.length + 1}`,
            value: deviceInfo.deviceId,
          };
          this.videoSourceOptions.push(option);
          if (option.text === this.stream.getVideoTracks()[0].label) {
            this.videoSource = option.value;
            window.localStorage.setItem(
              this.storageCameraSettingIndex,
              option.value
            );
          }
        }
      }
    },
    getStream() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      let video_constraints = { facingMode: "environment" };
      if (this.videoSource) {
        video_constraints = { deviceId: { exact: this.videoSource } };
        window.localStorage.setItem(
          this.storageCameraSettingIndex,
          this.videoSource
        );
      }
      const constraints = {
        video: video_constraints,
        audio: false,
      };
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.displayStream)
        .catch((error) => console.error("Error: ", error));
    },
    displayStream(stream) {
      this.stream = stream;
      this.$refs.video.srcObject = stream;
      this.$refs.video.setAttribute("playsinline", "true");
      this.$refs.video.onloadedmetadata = () => {
        this.$refs.video.play().then(() => {
          this.isScanning = true;
          this.setCanvasSize();
          this.streamVideo();
          this.scan();
        });
      };
    },
    tick() {
      const ctx = this.$refs.canvas.getContext("2d");
      ctx.drawImage(
        this.$refs.video,
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      if (this.decodedData !== null) {
        this.paintOutline(this.decodedData);
      }
    },
    async streamVideo() {
      while (this.isScanning) {
        this.tick();
        await this.timeout(1000 / this.videoFPS);
      }
    },
    async scan() {
      while (this.isScanning) {
        const imageData = this.$refs.canvas
          .getContext("2d")
          .getImageData(
            0,
            0,
            this.$refs.canvas.width,
            this.$refs.canvas.height
          );
        this.decodedData = jsQR(
          imageData.data,
          imageData.width,
          imageData.height
        );
        await this.timeout(1000 / this.scannerFPS);
      }
    },
    paintOutline(decodedData) {
      const startingPoint = decodedData.location.topRightCorner;
      const drawingPoints = [
        decodedData.location.topLeftCorner,
        decodedData.location.bottomLeftCorner,
        decodedData.location.bottomRightCorner,
      ];

      const ctx = this.$refs.canvas.getContext("2d");
      ctx.strokeStyle = "red";

      ctx.beginPath();
      ctx.moveTo(startingPoint.x, startingPoint.y);
      for (const { x, y } of drawingPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(startingPoint.x, startingPoint.y);
      ctx.closePath();
      ctx.stroke();
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  created() {
    const storedCamera = window.localStorage.getItem(
      this.storageCameraSettingIndex
    );
    if (storedCamera) {
      this.videoSource = storedCamera;
    }
    this.getStream().then(this.getDevices);
  },
  beforeDestroy() {
    if (this.stream) {
      this.stream.getTracks()[0].stop();
    }
  },
};
</script>

<style scoped lang="sass">
#video-container
  position: relative
  width: inherit
  height: inherit

  canvas
    height: inherit
    width: inherit
    background-color: black

  #video-source-select
    position: absolute
    top: 0
    left: 0
    width: 30%

  #scanner-cancel-button
    position: absolute
    top: 0
    right: 0
    width: 30%
</style>
